/* eslint-disable react/jsx-props-no-spreading */
import {
  FC,
  useEffect,
  useState,
} from 'react';
import Error from 'next/error';
import { useDispatch, useSelector } from 'react-redux';
import * as Bowser from 'bowser';
import Cookie from 'cookie';

import type { Text } from 'src/__generated__/graphqlTypes';
import StickyOfferContainer from 'components/StickyOffer';
import MainLayoutContainer from 'components/MainLayoutContainer';
import LoaderContainer from 'components/Loader';
import CookiePolicyContainer from 'components/CookiePolicy';
import { Store } from 'app-redux/types/storeTypes';
import {
  setLoadingState,
  setMobileViewWidthState,
  setServerCookies,
  setUserAgent,
  setViewerDevice,
} from 'app-redux/actions/appActions';
import { ViewerDevice } from 'constants/enums';
import { useStoreClientTimezone, useEnhancedPricing } from 'lib/storage.service';
import {
  mapGoogleServices,
  mapPageScripts,
  mapPageStyles,
} from 'lib/tag.service';
import {
  IS_CLIENT_SIDE,
  ONE_DAY_IN_MS,
  VIEWER_DEVICE,
} from 'constants/constants';
import { MainContainerInterface } from 'components/declarations';
import { useInitDataDog } from 'lib/dataDog';
import { Logger } from 'lib/logger';
import contentfulPages from 'lib/contentful/pages';
import {
  useInitialSetup,
  useRouteChangeCompleat,
  useRouteChangeStart,
} from 'src/shared/lib/history/hooks';
import { useAuthCacheHandler } from 'src/shared/lib/cookie/hooks';

import AgentChatContainer from './AgentChat/AgentChatContainer';
import ContentContainer from './Content/ContentContainer';

import GlobalStyles from '../app/styles/ui/GlobalStyles';

const mockUserAgent: Bowser.Parser.ParsedResult = {
  browser: {},
  os: {},
  platform: {},
  engine: {},
};

let isFirstLoad = true;
const MainContainer: FC<MainContainerInterface> = ({
  children,
  ...rest
}) => {
  const slug = useSelector((store: Store) => store.server.page.slug);
  const page = useSelector((store: Store) => store.server.page.pages[slug]);
  const isWindowExists = typeof window !== 'undefined';
  const userAgent = (isWindowExists && window.navigator.userAgent)
    ? Bowser.parse(window.navigator.userAgent)
    : mockUserAgent;

  const isDesktopLikeTablet = (userAgent.os.name?.toLowerCase() === 'macos'
    || userAgent.os.name?.toLowerCase() === 'linux')
        && userAgent.platform.type?.toLowerCase() === 'desktop'
        && navigator.maxTouchPoints > 1;
  const isTabletIOS = isDesktopLikeTablet
    || userAgent.platform.type?.toLowerCase() === ViewerDevice.TABLET;

  const [stickyOfferData, setStickyOfferData] = useState<any>(null);
  const dispatch = useDispatch();

  useInitDataDog();
  useInitialSetup();
  useRouteChangeStart();
  useRouteChangeCompleat();
  useAuthCacheHandler();

  if (IS_CLIENT_SIDE && isFirstLoad) {
    try {
      const cookies = Cookie.parse(document.cookie);
      dispatch(setServerCookies(cookies));
      const viewerDeviceCookie = cookies?.[VIEWER_DEVICE];
      const platformType = isDesktopLikeTablet ? ViewerDevice.TABLET : userAgent.platform.type;

      if (platformType && viewerDeviceCookie !== platformType) {
        dispatch(setViewerDevice(platformType as ViewerDevice));
        dispatch(setMobileViewWidthState(userAgent.platform.type === ViewerDevice.MOBILE));
      }

      isFirstLoad = false;
    } catch (e) {
      Logger.error(e);
      dispatch(setServerCookies({}));
    }
  }

  useEffect(() => {
    try {
      const contentfulPagesNames = JSON.parse(process.env.NEXT_PUBLIC_CONTENTFUL_PAGES || '[]');
      contentfulPages.initiate(contentfulPagesNames);
    } catch (e) {
      Logger.error(e);
    }

    const handlePageShow = (event: PageTransitionEvent) => {
      if (event.persisted) {
        dispatch(setLoadingState(false));
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  useEffect(() => {
    if (!window.navigator.userAgent) {
      Logger.error(`No UserAgent found at the ${window.location.pathname}`);
    }

    const cookieOptions: Cookie.CookieSerializeOptions = {
      secure: Boolean(process.env.NEXT_PUBLIC_CP_DOMAIN
        && window.location.origin.includes(process.env.NEXT_PUBLIC_CP_DOMAIN)),
      maxAge: ONE_DAY_IN_MS * 100,
      path: '/',
    };

    if (isTabletIOS) {
      document.cookie = Cookie.serialize(VIEWER_DEVICE, ViewerDevice.TABLET, cookieOptions);
    } else if (userAgent.platform.type) {
      document.cookie = Cookie.serialize(VIEWER_DEVICE, userAgent.platform.type, cookieOptions);
    }

    dispatch(setUserAgent(userAgent));
  }, [dispatch, userAgent]);

  useEffect(() => {
    if (isTabletIOS) {
      dispatch(setViewerDevice(ViewerDevice.TABLET));
    }
  }, [isTabletIOS, dispatch]);

  useStoreClientTimezone();
  useEnhancedPricing(slug);

  if (!page) {
    return <Error statusCode={404} />;
  }

  const {
    header,
    footer,
    stickyOffer,
    cookiePolicy,
    loader,
    googleServicesTagsCollection,
    pageScriptsCollection,
    pageStylesCollection,
    seo,
    sidebar,
    externalDataCollection,
    ...restParams
  } = page || {};

  const googleServices = googleServicesTagsCollection?.items as Array<Text> || [];
  const { scriptArray, styleArray, noscriptArray } = mapGoogleServices(googleServices);
  const externalTags = (externalDataCollection?.items || []) as Array<Text>;
  const pageScripts = pageScriptsCollection?.items as Array<Text> || [];
  const pageStyles = pageStylesCollection?.items as Array<Text> || [];
  const mappedScripts = mapPageScripts([...scriptArray, ...pageScripts]);
  const stylesArray = mapPageStyles([...styleArray, ...pageStyles]);

  return (
    <>
      {stickyOffer && (
        <StickyOfferContainer
          setStickyOfferData={setStickyOfferData}
          stickyOfferData={stickyOfferData}
          stickyOffer={stickyOffer}
        />
      )}
      <AgentChatContainer />
      {cookiePolicy && (
        <CookiePolicyContainer
          isStickyOfferExists={!!stickyOfferData}
          cookiePolicy={cookiePolicy}
        />
      )}
      {loader && (
        <LoaderContainer
          image={loader}
          withBackground
        />
      )}
      <GlobalStyles sidebar={sidebar} />
      {/* @ts-ignore */}
      <MainLayoutContainer
        header={header!}
        footer={footer!}
        seo={seo}
        externalNoScripts={noscriptArray}
        externalScripts={mappedScripts}
        externalStyles={stylesArray}
        externalTags={externalTags}
        {...{ ...rest, ...restParams }}
      >
        <ContentContainer>
          {children}
        </ContentContainer>
      </MainLayoutContainer>
    </>
  );
};

export default MainContainer;
