import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import type { Store } from 'app-redux/types/storeTypes';

import { getNumber } from './phone';

export const useCtmNumber = (defaultNumber: string) => {
  const ctmNumber = useSelector((store: Store) => store.client.app.ctmNumber);
  const number = useMemo(() => getNumber(defaultNumber, ctmNumber), [defaultNumber, ctmNumber]);

  return number;
};
