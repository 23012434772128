/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  FC,
  useState,
  useEffect,
  memo,
} from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { Store } from 'src/redux/types/storeTypes';
import styles from 'components/Footer/Footer.module.scss';
import FooterCopyright from 'components/Footer/Copyright';
import FooterLinksContainer from 'components/Footer/Links';
import { FooterAdditionalItem, Link } from 'src/__generated__/graphqlTypes';
import { FooterInterface } from 'components/Footer/declarations';
import { FooterType } from 'constants/enums';
import ExtraInfo from 'components/Footer/ExtraInfo/ExtraInfo';
import { capitalizeFirstLetter } from 'lib/text.service';

const Footer: FC<FooterInterface> = ({
  footer,
  isVisibleScrollTopButton,
  scrollToTop,
}) => {
  const [isBioPage, setIsBioPage] = useState(false);

  useEffect(() => {
    const hasBioPageClass = document.body.classList.contains('Bio-page');
    setIsBioPage(hasBioPageClass);
  }, []);

  const footerType = (footer.type || FooterType.ORANGE) as FooterType;
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);

  return (
    <footer className={cn(
      styles.footer,
      styles[`footer${capitalizeFirstLetter(footerType)}`],
      isBioPage && styles.bioFooter,
    )}
    >
      {isMobileViewWidth && (
        <ExtraInfo additional={footer.additionalCollection?.items as Array<FooterAdditionalItem>} />
      )}
      {(footer.type === FooterType.LIGHT)
          && (
            <ExtraInfo additional={
              footer.additionalCollection?.items as Array<FooterAdditionalItem>
            }
            />
          )}
      <FooterLinksContainer
        navBar={footer.nav!}
        mobileApps={footer.mobile!}
        footerType={footerType}
        socialMedias={footer.socialMediasCollection?.items as Array<Link>}
        additional={footer.additionalCollection?.items as Array<FooterAdditionalItem>}
      />
      {!isMobileViewWidth && (
        <ExtraInfo additional={footer.additionalCollection?.items as Array<FooterAdditionalItem>} />
      )}
      <FooterCopyright
        copyrightDescription={footer.copyrightDescription!}
        desktopSiteRequest={footer.desktopSiteRequest!}
        footerType={footerType}
        mobileSiteRequest={footer.mobileSiteRequest!}
        copyrightLinks={footer.copyrightLinksCollection?.items as Array<Link>}
      />
      {isVisibleScrollTopButton && (
        <div
          role="button"
          tabIndex={0}
          onClick={scrollToTop}
          onKeyPress={scrollToTop}
          style={{ backgroundImage: `url(${footer.scrollTop?.url})` }}
          className={styles.footerScrollTop}
        />
      )}
    </footer>
  );
};

export default memo(Footer);
