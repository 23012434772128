import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cookie from 'js-cookie';
import hash from 'object-hash';
import axios, { CancelTokenSource } from 'axios';

import { useCustomRouter } from 'src/shared/lib/history/hooks';
import { getSortIdQueryParam } from 'src/shared/lib/url';
import {
  CURRENT_VIEW_COOKIE,
  HOMEPAGE_SLUG,
  LOWERCASE_SORT_ID_KEY,
  PSYCHICS_READINGS_NEW_SLUG,
} from 'constants/constants';
import type { Store } from 'app-redux/types/storeTypes';
import { PsychicCardAppearance } from 'constants/enums';
import { Logger } from 'lib/logger';
import { getFilteredPsychicsIds, getPsychicFilterOptions } from 'src/api/psychicApi';
import { usePsychicStatuses, usePsychicStatusesWithUnsubscribe } from 'src/firebase/firebase.hook';
import type { RightPsychic, SectionExtraDataType } from 'types/objectTypes';
import { setFirebaseRequiredState } from 'app-redux/actions/appActions';
import { capitalizeFirstLetter } from 'lib/text.service';
import { makePayloadToGetPsychic, shouldUseTestPsychics } from 'lib/psychic.service';
import { Block } from 'src/__generated__/graphqlTypes';
import { getCancelSource } from 'src/api/axios';

import { defaultFilterState } from './constants';

import {
  FilterToolbarOptions,
  FilterToolbarSelectionsProps,
  LocalBlock,
  CategoryFilterByCategories,
  FilterToolbarCategoryOptions,
  PopularFilters,
} from '../declarations';

import { getSortIdCookie } from '.';

export const useSortIdQueryParam = (slug: string) => {
  const router = useCustomRouter();
  const { query } = router;
  const sortingIdQueryParam = getSortIdQueryParam(query);

  useEffect(() => {
    const sortingIdCookie = getSortIdCookie();

    if ((slug === HOMEPAGE_SLUG || slug === PSYCHICS_READINGS_NEW_SLUG)
      && !sortingIdQueryParam && sortingIdCookie) {
      const cookieForQuery = sortingIdCookie.replace(/^"|"$/g, '');
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set(LOWERCASE_SORT_ID_KEY, cookieForQuery);
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      router.replace(newUrl);
    }
  }, [sortingIdQueryParam, router, slug]);

  return sortingIdQueryParam || '';
};

export const useEmbeddedItemPosition = (
  appearance: PsychicCardAppearance,
  properties?: Record<string, any>,
) => {
  const isMobile = useSelector((store: Store) => store.server.app.isMobileViewWidth);

  if (isMobile) {
    const defaultValue = 5;

    if (typeof properties?.mobile === 'number') {
      return properties.mobile + 1;
    }

    if (typeof properties?.mobile !== 'object') {
      return defaultValue;
    }

    if (appearance === PsychicCardAppearance.TILE) {
      return properties.mobile.tile + 1 || defaultValue;
    }

    if (appearance === PsychicCardAppearance.LIST) {
      return properties.mobile.list + 1 || defaultValue;
    }

    return defaultValue;
  }

  const defaultValue = 4;

  if (typeof properties?.desktop === 'number') {
    return properties.desktop + 1;
  }

  if (typeof properties?.desktop !== 'object') {
    return defaultValue;
  }

  if (appearance === PsychicCardAppearance.TILE) {
    return properties.desktop.tile + 1 || defaultValue;
  }

  if (appearance === PsychicCardAppearance.LIST) {
    return properties.desktop.list + 1 || defaultValue;
  }

  return defaultValue;
};
const MAX_REQUEST_ATTEMPTS_FOR_PSYCHIC_IDS = 5;
export const useFilteredExtIdsState = (block?: LocalBlock) => {
  const [filteredExtIds, setFilteredExtIds] = useState<Array<number>>([]);

  const prevCancellationSource = useRef<CancelTokenSource>();
  const prevRequestHash = useRef<string>('');
  const user = useSelector((store: Store) => store.server.auth.user);
  const router = useCustomRouter();
  const isSameRequest = (currentHash: string) => {
    if (prevRequestHash.current === currentHash) {
      return true;
    }

    prevRequestHash.current = currentHash;

    return false;
  };
  const attemptToCancelPrevRequest = (cancelSource: CancelTokenSource) => {
    if (prevCancellationSource.current) {
      prevCancellationSource.current.cancel();
    }

    prevCancellationSource.current = cancelSource;
  };
  const performRequest = async (requestParameters: Record<string, any>, counter: number = 0) => {
    const requestHash = hash(requestParameters);

    if (isSameRequest(requestHash)) {
      return;
    }

    const cancelSource = getCancelSource();
    attemptToCancelPrevRequest(cancelSource);

    getFilteredPsychicsIds(requestParameters, cancelSource)
      .then((res) => setFilteredExtIds(res.ids))
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }

        prevRequestHash.current = '';
        const isCloudFrontTimeoutError = (typeof error === 'string' && error.includes('503'))
          || (typeof error.message === 'string' && error.message.includes('503'));

        if (isCloudFrontTimeoutError && counter < MAX_REQUEST_ATTEMPTS_FOR_PSYCHIC_IDS) {
          Logger.warn('Resend request to get psychics\' ids');

          return performRequest(requestParameters, counter + 1);
        }

        Logger.error(error);
      });
  };

  useEffect(() => {
    if (!block?.psychics) {
      return;
    }

    const { search } = router.query;

    if (!search) {
      return;
    }

    const requestParameters = {
      SearchText: search,
      PageSize: block.psychicsTotal || 100,
      PageIndex: 0,
      ApplyNewSorting: process.env.NEXT_PUBLIC_IS_NEW_PSYCHICS_SORTING,
      SortBy: 'BestAvailable',
      SortByText: 'Availability',
      CustId: user?.custId,
    };
    performRequest(requestParameters);
  }, [block?.psychics, user]);

  const getFilteredExtIds = (parameters: Record<string, any>, totalCount: number) => {
    const requestParameters = { ...parameters, PageSize: totalCount || 300, PageIndex: 0 };
    performRequest(requestParameters);
  };

  return [
    filteredExtIds,
    getFilteredExtIds,
  ] as [Array<number>, (parameters: Record<string, any>, total: number) => void];
};

export const useAppropriatePsychicsStatuses = (
  list: Array<number>,
  setPsychics: Dispatch<SetStateAction<RightPsychic[]>>,
  condition: boolean,
) => {
  usePsychicStatusesWithUnsubscribe(list, setPsychics, condition);
  usePsychicStatuses([...list], setPsychics, !condition);
};

export const useLoadFiltersOptions = (
  conditionToLoad: boolean = false,
  popularFilters?: PopularFilters,
  availabilityFilters?: PopularFilters,
) => {
  const [options, setOptions] = useState<FilterToolbarOptions>({ category: [], price: [] });
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const wasRequestSent = useRef<boolean | null>(null);

  useEffect(() => {
    if (wasRequestSent.current || !conditionToLoad) {
      return;
    }

    (async () => {
      try {
        setLoadingState(true);
        const {
          categoryOptions,
          priceOptions,
        } = await getPsychicFilterOptions();

        const price = (priceOptions || []).map((price: Record<string, any>) => ({
          ...price,
          psyGroup: price.psy_group,
        }));

        const filtersByCategoriesRaw = (categoryOptions || [])
          .reduce((
            acc: Record<string, CategoryFilterByCategories>,
            item: FilterToolbarCategoryOptions,
          ) => {
            if (!acc[item.majorCategoryID]) {
              const descriptionMap = {
                Skill: 'Ability',
                Subject: 'Subject',
                Tool: 'Tool',
              };

              const richTextMap = {
                Skill: 'Ability',
                Subject: 'Topics',
                Tool: 'Tools',
              };

              const majorCategoryDescription = descriptionMap[item.majorCategoryDescription]
              || item.majorCategoryDescription;
              const richText = richTextMap[item.majorCategoryDescription]
              || item.majorCategoryDescription;

              acc[item.majorCategoryID] = { majorCategoryDescription, richText, descriptions: [] };
            }

            acc[item.majorCategoryID].descriptions.push(item.description);

            return acc;
          }, {} as Record<string, CategoryFilterByCategories>);

        const majorCategoryIdDisplaySequence = [4, 3, 2, 1];
        const category = majorCategoryIdDisplaySequence
          .map((seq) => filtersByCategoriesRaw[seq])
          .filter((category) => category !== undefined);

        if (popularFilters) {
          category.push(popularFilters);
        }

        if (availabilityFilters) {
          category.push(availabilityFilters);
        }

        setOptions({ price, category });
      } finally {
        setLoadingState(false);
      }
    })();
  }, [conditionToLoad, wasRequestSent]);

  return { options, isLoading };
};

export const useFirebaseForSSR = (
  extraData?: SectionExtraDataType,
  psychics?: Array<RightPsychic>,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!psychics) {
      return;
    }

    const shouldUseFirebase = extraData
      && extraData.psychicCardAppearance !== PsychicCardAppearance.SIMPLE
      && extraData.psychicCardAppearance !== PsychicCardAppearance.SIMPLE_ADDITIONAL;

    if (shouldUseFirebase) {
      dispatch(setFirebaseRequiredState(true));
    }
  }, [psychics]);
};

export const useZeroResultPanel = (block: LocalBlock, filteringHeader: Record<string, any>) => {
  const [zeroResultPanelVisibility, setZeroResultPanelVisibility] = useState<boolean>(false);

  /** Set zeroResultPanelVisibility for server side psychics */
  useEffect(() => {
    if (!block.psychics || !filteringHeader) {
      return;
    }

    setZeroResultPanelVisibility(!block.psychicsTotal);
  }, [block.psychics]);

  return {
    zeroResultPanelVisibility,
    setZeroResultPanelVisibility,
  };
};

export const setFilterToolbarSelectionsFromExtraData = (
  extraData: SectionExtraDataType,
  filteringHeader: Record<string, any>,
): FilterToolbarSelectionsProps => {
  const { psychicCardAppearance, psychicCategory, sortingParameter, itemsLayout } = extraData;
  let appearance = psychicCardAppearance || defaultFilterState.psychicCardAppearance;

  if (filteringHeader) {
    const appearanceCookie = cookie.get(CURRENT_VIEW_COOKIE);

    if (appearanceCookie === '0') {
      appearance = PsychicCardAppearance.TILE;
    }

    if (appearanceCookie === '1') {
      appearance = PsychicCardAppearance.LIST;
    }
  }

  const categoryFilter = makePayloadToGetPsychic(psychicCategory);

  return ({
    ...defaultFilterState,
    selectedLayout: itemsLayout || defaultFilterState.selectedLayout,
    psychicCardAppearance: appearance,
    selectedSortItem: { SortBy: capitalizeFirstLetter(sortingParameter) || '' },
    selectedFilters: psychicCategory ? { ...categoryFilter } : defaultFilterState.selectedFilters,
  });
};

export const useFilterSelections = (
  block: LocalBlock,
  filteringHeader: Record<string, any>,
  extraData?: SectionExtraDataType,
) => {
  const [
    filterToolbarSelections,
    setFilterToolbarSelections,
  ] = useState<FilterToolbarSelectionsProps>(
    setFilterToolbarSelectionsFromExtraData(extraData || {}, filteringHeader),
  );

  /** Set zeroResultPanelVisibility for server side psychics */
  useEffect(() => {
    if (!block.psychics) {
      return;
    }

    setFilterToolbarSelections((prev) => ({ ...prev, totalResults: block.psychicsTotal || 0 }));
  }, [block.psychics]);

  return {
    filterToolbarSelections,
    setFilterToolbarSelections,
  };
};

export const useTestPsychicsSwitcher = () => {
  const user = useSelector((store: Store) => store.server.auth.user);

  return (query: any) => shouldUseTestPsychics(user, query);
};

export const useBannerImages = (blocks: Array<Block | null | undefined>) => {
  const isKarmaMember = useSelector((store: Store) => store.server.auth.user?.isKarmaMember);
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);

  return useMemo(() => {
    if (!isAuthenticated || !blocks) {
      return blocks;
    }

    const allowedSlugs = new Set(['banner']);

    if (isKarmaMember) {
      allowedSlugs.add('karma-member-banner');
    }

    return blocks
      .filter((block): block is Block => block !== null && block !== undefined)
      .filter((block) => {
        if (block.slug === 'non-karma-member-banner') {
          return !isKarmaMember;
        }

        return allowedSlugs.has(block.slug!);
      });
  }, [blocks, isKarmaMember, isAuthenticated]);
};
