import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import useSWR from 'swr';
import { useSelector } from 'react-redux';

import { getStatusApi } from 'src/api/psychicApi';
import { RightPsychic } from 'types/objectTypes';
import { Store } from 'app-redux/types/storeTypes';

const TIMEOUT = 30_000;

export const usePsychicUpdateFormApi = (
  setPsychics: Dispatch<SetStateAction<RightPsychic[]>>,
  id?: string,
) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const [key, setKey] = useState<string | null>(null);
  const fetcher = () => {
    if (id) {
      const params = user?.custId ? { CustId: user.custId } : {};

      return getStatusApi([id], params);
    }
  };
  const { data, error } = useSWR(key, fetcher, { refreshInterval: TIMEOUT });

  useEffect(() => {
    if (!id) {
      return;
    }

    const timer = setTimeout(() => setKey('bio-status-update'), TIMEOUT);

    return () => clearTimeout(timer);
  }, [id]);

  useEffect(() => {
    if (!data?.psychics?.length) {
      return;
    }

    const psychic = data.psychics[0] as RightPsychic;
    setPsychics((prev) => {
      const prevPsychic = prev?.[0];

      if (prevPsychic?.estimatedWaitTime === psychic.estimatedWaitTime) {
        return prev;
      }

      return [{
        ...prevPsychic,
        estimatedWaitTime: psychic.estimatedWaitTime,
      }];
    });
  }, [data]);

  return { data, error };
};
