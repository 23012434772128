import { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import type {
  Block,
  ImageWrapper,
  Link,
} from 'src/__generated__/graphqlTypes';
import { SectionComponentInterface } from 'types/componentTypes';
import type { Store } from 'app-redux/types/storeTypes';
import { useCommonContentfulBlocks } from 'lib/shared.hook';
import {
  CommonContentfulSlug,
  CommonSize,
  ElementAlign,
  ViewerDevice,
} from 'constants/enums';
import classes from 'src/styles/_commonClasses.module.scss';
import Divider from 'components/Sections/Divider';
import { DataLink } from 'components/Shared/SharedComponents';
import { capitalizeFirstLetter } from 'lib/text.service';

import styles from './TextAndSideAsset.module.scss';
import TextAndSideAssetBlock from './TextAndSideAssetBlock';
import { Title, RichText } from './RichText';
import CustomAsset from './CustomAsset';
import ExtraButtons from './ExtraButtons';

import {
  useAssetAlign,
  useAttachedImageHeight,
  useCurrentWidth as useCurrentViewerByWidth,
  useSectionClasses,
} from '../lib/hooks';
import { getExtraComponents } from '../lib';

const TextAndSideAssetContainer: FC<SectionComponentInterface> = ({
  blocks,
  extraData,
  bgColor,
}) => {
  const {
    mobileViewMaxWidth,
    commonPageMaxWidth,
  } = useSelector((store: Store) => store.server.app.scssVariables);
  const {
    content: contentBlock,
    topDivider,
    bottomDivider,
  } = useCommonContentfulBlocks<CommonContentfulSlug, Block>(blocks);
  const viewerDevice = useCurrentViewerByWidth();
  const isDesktop = viewerDevice === ViewerDevice.DESKTOP;
  const assetAlign = useAssetAlign(viewerDevice, extraData);
  const {
    buttonWithContent = true,
    titleAlign = ElementAlign.RIGHT,
    sectionSize = CommonSize.MEDIUM,
    videoAutoplay = false,
    pAlign,
    colorTheme,
  } = extraData || {};
  const richTextExtraData = { titleAlign, sectionSize, pAlign };
  const {
    content,
    richTitle,
    imagesCollection,
    link,
    contentTypesCollection,
  } = contentBlock;
  const allBlocks = contentTypesCollection?.items as Array<Block | Link> || [];
  const images = imagesCollection?.items as Array<ImageWrapper>;
  const attachedImageHeight = useAttachedImageHeight(images, viewerDevice, extraData);
  const sectionClasses = useSectionClasses(attachedImageHeight, extraData);
  const { extraBlocks, extraButtons } = getExtraComponents(allBlocks);
  const ctmNumber = useSelector((store: Store) => store.client.app.ctmNumber);

  const block = (
    <TextAndSideAssetBlock
      assetAlign={assetAlign}
      sectionSize={sectionSize}
      blocks={extraBlocks!}
      mobileViewMaxWidth={mobileViewMaxWidth}
    />
  );

  const isBlockContentVisible = extraBlocks?.length > 0;

  return (
    <section
      className={cn(
        styles.wrapper,
        sectionClasses,
        classes[`themeColor${capitalizeFirstLetter(colorTheme)}`],
      )}
      style={{
        background: bgColor,
        height: attachedImageHeight
          ? `${attachedImageHeight}px`
          : undefined,
      }}
    >
      <Divider
        block={topDivider}
        maxWidth={`${commonPageMaxWidth}px`}
      />
      <Title
        richText={richTitle}
        ctmNumber={ctmNumber}
        richTextExtraData={richTextExtraData}
        extraClasses={{ titleClasses: styles.wrapperTitle }}
      />
      <div className={styles[`${assetAlign}`]}>
        <CustomAsset
          viewerDevice={viewerDevice}
          block={contentBlock}
          extraData={extraData}
          videoAutoplay={videoAutoplay}
        />
        {(isDesktop || content) && (
          <div className={styles[`${assetAlign}Content`]}>
            <RichText
              richText={content}
              ctmNumber={ctmNumber}
              richTextExtraData={richTextExtraData}
              extraClasses={{
                paragraphClasses: isBlockContentVisible
                  ? styles[`${assetAlign}ContentParagraphMargin`]
                  : '',
                titleClassesH3: styles[`${assetAlign}ContentTitleH3`],
                titleClassesH2: styles.titleH2,
              }}
            />
            {isDesktop && block}
            <div className={styles[`${assetAlign}ContentButtonWrapper`]}>
              {buttonWithContent && link && (
                <DataLink
                  link={link}
                  className={styles[`${assetAlign}ContentButton`]}
                >
                  {link?.title}
                </DataLink>
              )}
              <ExtraButtons
                extraButtons={extraButtons}
                assetAlign={assetAlign}
                buttonWithContent={buttonWithContent}
              />
            </div>
          </div>
        )}
      </div>
      {!isDesktop && isBlockContentVisible && (
        <div className={styles[`${assetAlign}Content`]}>
          {block}
        </div>
      )}
      {!buttonWithContent && link && (
        <DataLink
          link={link}
          className={styles.wrapperButton}
        />
      )}
      <Divider
        block={bottomDivider}
        maxWidth={`${commonPageMaxWidth}px`}
      />
    </section>
  );
};

export default TextAndSideAssetContainer;
