import React, {
  useState,
  useEffect,
  FC,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Store } from 'app-redux/types/storeTypes';
import { getRightPsychicsProxy } from 'src/api/psychicApi';
import {
  Block,
  BlockContentTypesItem,
  ImageWrapper,
  Link,
  Text,
} from 'src/__generated__/graphqlTypes';
import { Logger } from 'lib/logger';
import { DataButton } from 'components/Shared/SharedComponents';
import { updatePsychicListOptionCookie, updatePsychicListOptionCookieMultiple } from 'src/shared/lib/cookie';
import { useCustomRouter } from 'src/shared/lib/history/hooks';

import FilterListPanelMobile from './FilterListPanel/FilterListPanelMobile';
import FilterListPanel from './FilterListPanel/FilterListPanel';
import styles from './PsychicFilter.module.scss';
import StatusIndicators from './HeaderToolbar/StatusIndicators';
import SortBy from './HeaderToolbar/SortBy';
import FilterBy from './HeaderToolbar/FilterBy';
import ProfileView from './HeaderToolbar/ProfileView';

import {
  PsychicFilterInterface,
  SelectedSort,
  SelectedValueChange,
  PopularFilters,
  ActiveFilterCounter,
} from '../../declarations';
import {
  psychicFilterReducer,
  psychicFilterWrapperReducer,
  sortImageReducer,
  filterImageReducer,
  gridViewReducer,
  listViewReducer,
} from '../../lib';
import { requestBody } from '../../lib/constants';

const PsychicFilter: FC<PsychicFilterInterface> = ({
  content,
  filteredExtIds,
  apiData,
  sortid,
  filterToolbarSelections,
  isFilterVisible,
  psychicCategory,
  zeroResultPanelVisibility = true,
  clearFilter,
  displayGridView,
  displayListView,
  updateFilter,
}) => {
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const {
    selectedLayout,
    selectedFilters,
    selectedSortItem,
    totalAvailablePsychics,
    totalBusyPsychics,
    totalResults,
  } = filterToolbarSelections || {};
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const [selectedSortItemLocal, setSelectedSortItem] = useState<SelectedSort>({ SortBy: '', SortByText: '' });
  const [isMobileSortSelected, setMobileSortSelectedState] = useState<boolean>(false);
  const [selectedFiltersLocal, setSelectedFilters] = useState<Record<string, any>>(
    selectedFilters || {},
  );
  const [activeFiltersCounter, setActiveFiltersCounter] = useState<ActiveFilterCounter>({
    beforeApply: 0,
    afterApply: psychicCategory ? 1 : 0,
  });

  const [totalResultsLocal, setTotalResults] = useState<number | undefined>(totalResults);
  const [zeroResultPanelVisibilityLocal, setZeroResultPanelVisibility] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [isFetchingFilteredCount, setFetchingState] = useState<boolean>(false);
  const filterByTitleRef = useRef<HTMLDivElement>(null);
  const router = useCustomRouter();

  useEffect(() => {
    setTotalResults(totalResults);
  }, [totalResults]);

  useEffect(() => {
    if (!selectedSortItem) {
      return;
    }

    setSelectedSortItem(selectedSortItem);
  }, [selectedSortItem]);

  useEffect(() => {
    setZeroResultPanelVisibility(zeroResultPanelVisibility);
  }, [zeroResultPanelVisibility]);

  const toggleFilters = () => {
    if (showFilters && !totalResults) {
      setZeroResultPanelVisibility(true);
    }

    updatePsychicListOptionCookie('ResultType', 2);
    setShowFilters(!showFilters);
  };

  const {
    contentTypesCollection,
  } = content || {};

  const children = contentTypesCollection?.items as Array<Block>;

  const {
    sort,
    filter,
    gridViewBlock,
    listViewBlock,
    availableTitle,
    busyTitle,
    backToFiltersTitle,
    noMatchTitle,
  } = psychicFilterWrapperReducer(children) || {};

  const {
    contentTypesCollection: sortTypesCollection,
    imagesCollection: sortImageColection,
    richTitle: sortTitle,
  } = sort || {};
  const sortList = sortTypesCollection?.items as Array<Text> | undefined;
  const {
    sortImage,
  } = sortImageReducer(sortImageColection?.items as Array<ImageWrapper> || []);

  const {
    contentTypesCollection: gridContentTypesCollection,
  } = gridViewBlock || {};
  const {
    gridViewLink,
  } = gridViewReducer(gridContentTypesCollection?.items as Array<BlockContentTypesItem> || []);

  const {
    contentTypesCollection: listContentTypesCollection,
  } = listViewBlock || {};
  const {
    listViewLink,
  } = listViewReducer(listContentTypesCollection?.items as Array<BlockContentTypesItem>) || [];

  const {
    contentTypesCollection: filterTypesCollection,
    imagesCollection: filterImageColection,
    richTitle: filterTitle,
  } = filter || {};
  const {
    filterImage,
  } = filterImageReducer(filterImageColection?.items as Array<ImageWrapper> || []);
  const filterButtonAndLinkList = filterTypesCollection?.items as Array<Block | Link> | undefined;

  const changeFilter = async (selectedValue: SelectedValueChange) => {
    if (!selectedValue) {
      return;
    }

    try {
      if (selectedFiltersLocal && Object.keys(selectedFiltersLocal).length) {
        const filterType = selectedValue.type;
        const currrentSearchOptions = selectedFiltersLocal.SearchOptions || {};
        const existingFilterValue = currrentSearchOptions[filterType];
        let updatedFilterValue = '';

        if (existingFilterValue) {
          const existingFilterValueArr = existingFilterValue.split(',');
          const isFilterAlreadyExist = existingFilterValueArr
            .find((ele: string) => ele === selectedValue.value);

          if (isFilterAlreadyExist) {
            const filtervaluesAfterRemoving = existingFilterValueArr
              .filter((ele: string) => ele !== selectedValue.value);
            updatedFilterValue = filtervaluesAfterRemoving.join(',');
            setActiveFiltersCounter((prev) => ({ ...prev, beforeApply: prev.beforeApply - 1 }));
          } else {
            existingFilterValueArr.push(selectedValue.value);
            updatedFilterValue = existingFilterValueArr.join(',');
            setActiveFiltersCounter((prev) => ({ ...prev, beforeApply: prev.beforeApply + 1 }));
          }
        } else {
          setActiveFiltersCounter((prev) => ({ ...prev, beforeApply: prev.beforeApply + 1 }));
          updatedFilterValue = selectedValue.value;
        }

        const valuesToSet = { [filterType]: updatedFilterValue };
        setSelectedFilters({
          SearchOptions: { ...currrentSearchOptions, ...valuesToSet },
        });
      } else {
        const searchOptionTemplate = { Subject: '', Ability: '', Tool: '', Style: '', Price: '', Popular: '', isNewPsychics: '', isAppointmentPsychics: '' };
        setSelectedFilters({
          SearchOptions: { ...searchOptionTemplate, [selectedValue.type]: selectedValue.value },
        });
        setActiveFiltersCounter((prev) => ({ ...prev, beforeApply: prev.beforeApply + 1 }));
      }
    } catch (e) {
      Logger.error(e);
    }
  };

  const checkIfFilterPresent = (selectedValue: SelectedValueChange) => {
    if (selectedFiltersLocal && Object.keys(selectedFiltersLocal).length) {
      const filterType = selectedValue.type;
      const currrentSearchOptions = selectedFiltersLocal.SearchOptions;
      const existingFilterValue = currrentSearchOptions[filterType];

      if (existingFilterValue) {
        const existingFilterValueArr = existingFilterValue.split(',');

        return existingFilterValueArr.find((ele) => ele === selectedValue.value) !== undefined;
      }
    }

    return false;
  };

  const changeSort = (value: Text) => {
    if (!value) {
      return;
    }

    if (!isMobileSortSelected && isMobileViewWidth) {
      setMobileSortSelectedState(true);
    }

    try {
      const selectedValue: SelectedSort = {
        SortBy: value.fullText,
        SortByText: value.text,
      };
      updateFilter?.(selectedValue, selectedFiltersLocal);
      updatePsychicListOptionCookieMultiple({
        SortBy: selectedValue.SortBy,
        SortByText: selectedValue.SortByText,
      });
      setShowFilters(false);
    } catch (e) {
      Logger.error(e);
    }
  };

  const closeFilter = () => {
    setShowFilters(false);
  };

  const doneFilter = () => {
    updateFilter?.(selectedSortItemLocal, selectedFiltersLocal);
    updatePsychicListOptionCookie('ResultType', 1);
    setShowFilters(false);

    setActiveFiltersCounter((prev) => ({
      beforeApply: 0,
      afterApply: prev.afterApply + prev.beforeApply,
    }));
  };

  const removeFilter = () => {
    updatePsychicListOptionCookie('SearchOptions', null);
    clearFilter?.();
    setActiveFiltersCounter({ beforeApply: 0, afterApply: 0 });
    setSelectedFilters({});
    setTotalResults(totalResults);
  };

  useEffect(() => {
    if (!Object.keys(selectedFiltersLocal).length || !showFilters) {
      return;
    }

    (async () => {
      let currentRequestBody = { ...requestBody, PageSize: 1 };

      if (apiData) {
        const filteredApiData = Object.fromEntries(
          Object.entries(apiData).filter(([, value]) => value !== null),
        );
        currentRequestBody = { ...currentRequestBody, ...filteredApiData };
      }

      if (sortid) {
        currentRequestBody.SortId = sortid;
      }

      if (router.query.search) {
        currentRequestBody.SearchText = router.query.search;
      }

      if (Object.keys(selectedFiltersLocal.SearchOptions).length) {
        const anyFilterEnabled = Object
          .values(selectedFiltersLocal.SearchOptions)
          .filter((val) => Boolean(val));

        if (anyFilterEnabled.length) {
          updatePsychicListOptionCookie('SearchOptions', selectedFiltersLocal?.SearchOptions);
        } else {
          updatePsychicListOptionCookie('SearchOptions', null);
        }
      } else {
        updatePsychicListOptionCookie('SearchOptions', null);
      }

      currentRequestBody = {
        ...currentRequestBody,
        ...selectedSortItemLocal,
        ...selectedFiltersLocal,
      };

      try {
        setFetchingState(true);
        const { totalCount } = await getRightPsychicsProxy(currentRequestBody);
        setTotalResults(totalCount);
      } finally {
        setFetchingState(false);
      }
    })();
  }, [selectedFiltersLocal, apiData, sortid, filter]);

  const {
    clearFilterButton,
    doneButton,
    priceTitle,
    viewTitle,
    resultsTitle,
    popularTitle,
    popularPremiumFilter,
    newPsychicsFilter,
    customerFavoritesFilter,
    staffPicksFilter,
    risingStarsFilter,
    availabilityTitle,
    availableForAppointment,
  } = psychicFilterReducer(filterButtonAndLinkList) || {};

  const popularFiltersArray = [
    popularPremiumFilter,
    newPsychicsFilter,
    customerFavoritesFilter,
    staffPicksFilter,
    risingStarsFilter,
  ];

  const popularFiltersOptions = popularFiltersArray.map((option) => ({ displayVal: option?.text || '', val: option?.fullText || '' }));
  const popularFilters: PopularFilters = {
    majorCategoryDescription: popularTitle?.title || '',
    richText: popularTitle?.title || '',
    descriptions: popularFiltersOptions,
  };

  const availabilityFiltersOptions = [{
    displayVal: availableForAppointment?.fullText || '',
    val: 'yes',
  }];
  const availabilityFilters: PopularFilters = {
    majorCategoryDescription: availabilityTitle?.title || '',
    richText: availabilityTitle?.title || '',
    descriptions: availabilityFiltersOptions,
  };

  const renderContent = () => {
    /** We need this because My Favorites is from Sort but
      * acts like filter
   */
    if (zeroResultPanelVisibilityLocal && filterToolbarSelections.selectedSortItem.SortBy === 'MyFavorite') {
      return (
        <div className={styles.zeroResults}>
          <span className={styles.title}>{noMatchTitle?.title}</span>
        </div>
      );
    }

    if (isMobileViewWidth) {
      return (
        <FilterListPanelMobile
          isFetchingFilteredCount={isFetchingFilteredCount}
          filterTitle={filterTitle}
          showFilters={showFilters}
          checkIfFilterPresent={checkIfFilterPresent}
          changeFilter={changeFilter}
          doneFilter={doneFilter}
          totalResultsLocal={totalResultsLocal}
          removeFilter={removeFilter}
          clearFilterButton={clearFilterButton}
          doneButton={doneButton}
          priceTitle={priceTitle}
          viewTitle={viewTitle}
          resultsTitle={resultsTitle}
          closeFilter={closeFilter}
          filterByTitleRef={filterByTitleRef}
          popularFilters={popularFilters}
          availabilityFilters={availabilityFilters}
          popularTitle={popularTitle}
          availabilityTitle={availabilityTitle}
        />
      );
    }

    return (
      <FilterListPanel
        isFetchingFilteredCount={isFetchingFilteredCount}
        changeFilter={changeFilter}
        checkIfFilterPresent={checkIfFilterPresent}
        doneFilter={doneFilter}
        removeFilter={removeFilter}
        totalResultsLocal={totalResultsLocal}
        clearFilterButton={clearFilterButton}
        doneButton={doneButton}
        showFilters={showFilters}
        priceTitle={priceTitle}
        viewTitle={viewTitle}
        resultsTitle={resultsTitle}
        closeFilter={closeFilter}
        filterByTitleRef={filterByTitleRef}
        availabilityTitle={availabilityTitle}
        availableForAppointment={availableForAppointment}
      />
    );
  };

  /** Now should be not reachable because view filtered result button will
   * be disabled if 0 result
   */
  if (zeroResultPanelVisibilityLocal && filterToolbarSelections.selectedSortItem.SortBy !== 'MyFavorite') {
    return (
      <div className={styles.zeroResults}>
        <span className={styles.title}>{noMatchTitle?.title}</span>
        <DataButton
          onClick={() => {
            setShowFilters(true);
            setZeroResultPanelVisibility(false);
          }}
          className={styles.button}
        >
          {backToFiltersTitle?.title}
        </DataButton>
      </div>
    );
  }

  return (
    <>
      <div className={cn(
        styles.toolbar,
        {
          [styles.expanded]: isMobileSortSelected,
          [styles.common]: !isMobileSortSelected,
        },
      )}
      >
        <StatusIndicators
          extIds={filteredExtIds}
          totalAvailablePsychics={totalAvailablePsychics}
          totalBusyPsychics={totalBusyPsychics}
          availableTitle={availableTitle}
          busyTitle={busyTitle}
        />
        <div className={styles.filter}>
          <SortBy
            sortImage={sortImage}
            sortTitle={sortTitle}
            sortList={sortList}
            changeSort={changeSort!}
            viewerDevice={viewerDevice}
          />
          {isFilterVisible && (
            <FilterBy
              filterImage={filterImage}
              filterTitle={filterTitle}
              toggleFilters={toggleFilters}
              showFilters={showFilters}
              filterByTitleRef={filterByTitleRef}
              activeFiltersCounter={activeFiltersCounter}
              viewerDevice={viewerDevice}
            />
          )}
        </div>
        <ProfileView
          gridViewLink={gridViewLink}
          listViewLink={listViewLink}
          selectedLayout={selectedLayout}
          displayGridView={displayGridView}
          displayListView={displayListView}
        />
      </div>
      { renderContent() }
    </>
  );
};

export default PsychicFilter;
