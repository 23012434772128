import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IS_CLIENT_SIDE } from 'constants/constants';
import { ViewerDevice } from 'constants/enums';
import { Store } from 'app-redux/types/storeTypes';

export const useIsDesktop = (viewerDevice: ViewerDevice, horoscopeTabletMaxWidth: string) => {
  const clientWidth = useSelector((store: Store) => store.client.app.clientWidth);
  const checkIsDesktop = () => {
    if (clientWidth) {
      return +horoscopeTabletMaxWidth < clientWidth;
    }

    if (IS_CLIENT_SIDE) {
      return viewerDevice === ViewerDevice.DESKTOP && +horoscopeTabletMaxWidth < window.innerWidth;
    }

    return viewerDevice === ViewerDevice.DESKTOP;
  };
  const [isDesktop, setViewDesktopState] = useState<boolean>(checkIsDesktop());

  useEffect(() => {
    const isDesktop = +horoscopeTabletMaxWidth < clientWidth;
    setViewDesktopState(isDesktop);
  }, [clientWidth, horoscopeTabletMaxWidth]);

  return isDesktop;
};
