import { FC } from 'react';

import { Tick } from 'src/shared/ui/Tick';

import styles from './bioOverlay.module.scss';

export const BackButtonFromIframe: FC = () => {
  const sideSize = 15;

  return (
    <button
      type="button"
      style={{ height: sideSize * 1.4, width: sideSize * 1.4 }}
      className={styles.back}
      onClick={() => window.history.back()}
    >
      <Tick
        className={styles.backImage}
        sideSize={sideSize}
        borderWidth={3}
        color="#1C573D"
      />
    </button>
  );
};
