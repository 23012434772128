import {
  BLOCKS,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types';
import cn from 'classnames';
import { FC } from 'react';

import { CommonRichText } from 'components/Shared/SharedComponents';
import classes from 'src/styles/_commonClasses.module.scss';
import { RichTextParsersConfig, SectionExtraDataType } from 'types/objectTypes';
import { capitalizeFirstLetter } from 'lib/text.service';
import { mapBlocksEmbeddedImageWrapper, mapHyperlinkWithDynamicPhone } from 'lib/richTextMappers';
import { formatPhoneNumber } from 'src/shared/lib/phone';
import { useCtmNumber } from 'entities/CTMPhone';

import styles from './TextAndSideAsset.module.scss';

import { TextAndSideAssetRichTextInterface as RichTextInterface, TextAndSideAssetTextClassNames as TextClassNames } from '../config/declarations';

const getParsersConfig = (
  {
    titleAlign,
    sectionSize,
    pAlign,
  }: SectionExtraDataType,
  {
    titleClassesH3,
    titleClasses,
    paragraphClasses,
    titleClassesH2,
  }: TextClassNames = {},
  ctmNumber?: string,
): RichTextParsersConfig => {
  const pAlignClass = classes[`pAlign${capitalizeFirstLetter(pAlign || titleAlign)}`];
  const getHClasses = (specificClass?: string) => cn(
    styles[`${sectionSize}ContentTitle`],
    classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`],
    specificClass,
    titleClasses,
  );

  return ({
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(styles[`${sectionSize}ContentParagraph`], pAlignClass, paragraphClasses),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getHClasses(titleClassesH3),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHClasses(titleClassesH2),
    },
    [BLOCKS.HEADING_1]: {
      classNames: getHClasses(),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
    [BLOCKS.UL_LIST]: {
      classNames: {
        ul: styles.ul,
        li: styles.item,
        p: styles.paragraph,
      },
    },
    [BLOCKS.EMBEDDED_ENTRY]: {
      classNames: cn(pAlignClass, styles.imageEmbedded),
      mapper: mapBlocksEmbeddedImageWrapper,
    },
    [INLINES.HYPERLINK]: {
      classNames: styles.hyperlink,
      mapper: mapHyperlinkWithDynamicPhone({ telephone: formatPhoneNumber(ctmNumber) }),
    },
  });
};

const RichText: FC<RichTextInterface> = ({
  richText,
  richTextExtraData,
  extraClasses,
}) => {
  const ctmNumber = useCtmNumber('');

  if (!richText) {
    return null;
  }

  return (
    <CommonRichText
      content={richText}
      parsersConfig={getParsersConfig(richTextExtraData, extraClasses, ctmNumber)}
    />
  );
};

const Title = RichText;

export { Title, RichText };
