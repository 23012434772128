import {
  ChangeEvent,
  ExoticComponent,
  FC,
  HTMLAttributes,
  useCallback,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import type { TooltipRefProps } from 'react-tooltip';

import { searchPsychicByCriteriaProxy } from 'src/api/psychicApi';
import type { Store } from 'app-redux/types/storeTypes';
import { SEARCH_BOX_DEBOUNCE_DELAY } from 'constants/constants';
import { getAppropriatePathForResource } from 'lib/tag.service';
import { useCustomRouter } from 'src/shared/lib/history/hooks';
import { setIsAutoSearchCookie, updatePsychicListOptionCookie } from 'src/shared/lib/cookie';
import { BioOverlay } from 'src/shared/lib/bio-overlay';
import { ViewerDevice } from 'constants/enums';

import styles from './styles.module.scss';
import MagnifierTooltipButton from './MagnifierTooltipButton';
import SearchTooltip from './SearchTooltip';
import SearchBarInput from './SearchBarInput';
import MagnifierSearchButton from './MagnifierSearchButton';

import type { ISearchBar } from '../config/declarations';

/* Nested component */
const Wrapper: FC<HTMLAttributes<HTMLDivElement> | ExoticComponent> = ({ children }) => {
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const isMobile = viewerDevice !== ViewerDevice.DESKTOP;

  if (isMobile) {
    return <>{children}</>;
  }

  return (
    <div className={styles.search}>
      {children}
    </div>
  );
};

/* Main component */
const SearchBar: FC<ISearchBar> = ({
  search: searchBox,
  isTooltip = false,
  classNameIcon,
}) => {
  const router = useCustomRouter();
  const [searchValue, setSearchValue] = useState<string>(router.query.search as string || '');
  const [psychicsList, setPsychicsList] = useState<Array<any>>([]);

  const searchRef = useRef<HTMLInputElement>(null);
  const tooltipRef = useRef<TooltipRefProps>(null);
  const { image } = searchBox?.image || {};

  const getAndSetSearchValue = useCallback(debounce(async (newSearchValue: string) => {
    if (!newSearchValue) {
      setPsychicsList([]);
    } else {
      const array = await searchPsychicByCriteriaProxy(newSearchValue);
      const isNoCurrentValueButCached = !searchRef.current?.value && newSearchValue;
      setPsychicsList((isNoCurrentValueButCached || !array) ? [] : array);
    }
  }, SEARCH_BOX_DEBOUNCE_DELAY), []);

  const changeHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = e.target.value;
    setIsAutoSearchCookie(false);
    setSearchValue(newSearchValue);
    await getAndSetSearchValue(newSearchValue);
  };

  const clear = () => {
    setSearchValue('');
    updatePsychicListOptionCookie('SearchText', '');
    setPsychicsList([]);
  };

  if (!searchBox) {
    return null;
  }

  const search = () => {
    const trimmedSearchValue = searchValue.trim();

    if (trimmedSearchValue) {
      updatePsychicListOptionCookie('SearchText', trimmedSearchValue);
      const { isMatched, path } = getAppropriatePathForResource(searchBox.baseLink!);
      const href = `${path}?search=${trimmedSearchValue}`;

      BioOverlay.handleClickInIframe({} as any, href);

      if (isMatched) {
        window.location.href = href;
      } else {
        router.replace(href);
      }
    }
  };

  if (isTooltip) {
    return (
      <Wrapper>
        <MagnifierTooltipButton
          className={classNameIcon}
          button={searchBox.image}
          tooltipRef={tooltipRef}
        />
        <SearchTooltip
          tooltipRef={tooltipRef}
          searchRef={searchRef}
          searchValue={searchValue}
          searchBox={searchBox}
          changeHandler={changeHandler}
          search={search}
          clear={clear}
          psychicsList={psychicsList}
          image={image}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className={styles.box}>
        <MagnifierSearchButton
          button={searchBox.image}
          onClick={search}
        />
        <SearchBarInput
          tooltipRef={tooltipRef}
          searchRef={searchRef}
          searchValue={searchValue}
          searchBox={searchBox}
          search={search}
          changeHandler={changeHandler}
          clear={clear}
          psychicsList={psychicsList}
        />
      </div>
    </Wrapper>
  );
};

export default SearchBar;
