import { ParsedUrlQuery } from 'querystring';

import { FC } from 'react';
import {
  BLOCKS,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types';
import cn from 'classnames';

import styles from 'src/components/Sections/AboutHeroImage/AboutHeroImage.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import type { RichTextParsersConfig } from 'types/objectTypes';
import type { SectionComponentInterface } from 'types/componentTypes';
import {
  mapBlocksHeroImage,
  mapHyperlinkWithDynamicPhone,
  mapMarksBoldWithReplacement,
} from 'lib/richTextMappers';
import { capitalizeFirstLetter } from 'lib/text.service';
import { useCustomRouter } from 'src/shared/lib/history/hooks';
import { CommonRichText as Content, DataLink } from 'components/Shared/SharedComponents';
import { formatPhoneNumber } from 'src/shared/lib/phone';
import { useCtmNumber } from 'entities/CTMPhone';

const getParsersConfig = (query: ParsedUrlQuery, ctmNumber?: string): RichTextParsersConfig => ({
  [BLOCKS.EMBEDDED_ASSET]: {
    classNames: styles.image,
    mapper: mapBlocksHeroImage,
  },
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.paragraph,
  },
  [BLOCKS.HEADING_2]: {
    classNames: cn(styles.title),
  },
  [BLOCKS.HEADING_1]: {
    classNames: cn(styles.title),
  },
  [MARKS.BOLD]: {
    classNames: cn(classes.textBold, styles.bold),
    mapper: mapMarksBoldWithReplacement({ search: query.search as string }),
  },
  [INLINES.HYPERLINK]: {
    classNames: styles.hyperlink,
    mapper: mapHyperlinkWithDynamicPhone({ telephone: formatPhoneNumber(ctmNumber) }),
  },
});

/* Nested component */
const Title = Content;

/* Main component */
const AboutHeroImage: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const router = useCustomRouter();
  const ctmNumber = useCtmNumber('');
  const { maxWidth = 'local', verticalPadding, sectionStyle } = extraData || {};
  const [block] = blocks;

  if ((!router.query.search && router.pathname === '/psychic-not-found') || !block) {
    return null;
  }

  const { content, link, richTitle } = block;

  return (
    <section
      className={cn(
        styles.wrapper,
        sectionStyle ? styles[`${sectionStyle}`] : styles.common,
        {
          [classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`]]:
            Boolean(verticalPadding),
        },
      )}
      style={{ background: bgColor }}
    >
      <div className={cn(styles.hero, styles[`width${capitalizeFirstLetter(maxWidth)}`])}>
        <Title
          content={richTitle}
          parsersConfig={getParsersConfig(router.query, ctmNumber)}
        />
        <Content
          content={content}
          parsersConfig={getParsersConfig(router.query, ctmNumber)}
        />
        {link && (
          <DataLink link={link} href={link.src!} className={styles.heroButton}>
            {link.title}
          </DataLink>
        )}
      </div>
    </section>
  );
};

export default AboutHeroImage;
