import { FC } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import {
  CommonRichText,
  DataButton,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';
import { FilterByInterface } from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';
import { mapBlocksParagraphWithModifiableText } from 'lib/richTextMappers';
import { ViewerDevice } from 'constants/enums';
import { Tick } from 'src/shared/ui/Tick';

import styles from '../PsychicFilter.module.scss';

const getParsersConfig = (viewerDevice: ViewerDevice): RichTextParsersConfig => ({
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.filterByTitle,
    mapper: mapBlocksParagraphWithModifiableText((text: string) => {
      if (viewerDevice === ViewerDevice.DESKTOP) {
        return text;
      }

      return text.split(' ')?.[0];
    }),
  },
});

const FilterBy: FC<FilterByInterface> = ({
  filterImage,
  filterTitle,
  filterByTitleRef,
  viewerDevice,
  showFilters,
  activeFiltersCounter,
  toggleFilters,
}) => {
  const shouldShowCounter = activeFiltersCounter.afterApply > 0;
  const tickSize = (viewerDevice === ViewerDevice.MOBILE) ? 8 : 10;

  return (
    <DataButton
      className={styles.filterBy}
      ref={filterByTitleRef}
      onClick={toggleFilters}
    >
      {filterImage?.url && (
        <WebpImage
          image="external"
          src={filterImage?.url}
          className={styles.icon}
        />
      )}
      <CommonRichText
        content={filterTitle}
        parsersConfig={getParsersConfig(viewerDevice)}
      />
      {shouldShowCounter && (
        <span className={styles.counter}>
          {activeFiltersCounter.afterApply}
        </span>
      )}
      <Tick
        sideSize={tickSize}
        color="#1c573d"
        direction={showFilters ? 'top' : 'bottom'}
        className={cn(styles.tick, {
          [styles.tickTop]: showFilters,
        })}
      />
    </DataButton>
  );
};

export default FilterBy;
