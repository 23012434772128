import { FC, useState } from 'react';
import cn from 'classnames';

import styles from './PriceBasedPackages.module.scss';
import PackagePricing from './PackagePricing';

import { PriceBasedPackageInterface } from '../config/declarations';

const MobilePricePackages: FC<PriceBasedPackageInterface> = ({
  block,
}) => {
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(1);

  return (
    <>
      <ul className={styles.tabList}>
        { block.contentTypesCollection?.items?.map((item, i) => {
          // eslint-disable-next-line no-underscore-dangle
          if (item?.__typename !== 'Block') {
            return null;
          }

          return (
            <li key={item?.title}>
              <button
                data-action={item?.title?.toLowerCase()}
                data-category="tab"
                type="button"
                className={cn(
                  styles.tabListButton,
                  i === currentTabIndex && styles.tabListButtonSelected,
                )}
                onClick={() => setCurrentTabIndex(i)}
              >
                {item?.title}
              </button>
            </li>
          );
        })}
      </ul>
      <PackagePricing
        block={block}
        tabIndex={currentTabIndex}
      />
    </>
  );
};

export default MobilePricePackages;
