import { FC } from 'react';
import { useSelector } from 'react-redux';

import type { Store } from 'src/redux/types/storeTypes';

import MobilePricePackages from './MobilePricePackages';
import PackagePricing from './PackagePricing';

import { PriceBasedPackageInterface } from '../config/declarations';

const PriceBasedPackage: FC<PriceBasedPackageInterface> = ({
  block,
}) => {
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);

  if (isMobileViewWidth) {
    return (
      <MobilePricePackages block={block} />
    );
  }

  return (
    <PackagePricing block={block} />
  );
};

export default PriceBasedPackage;
