import { PsychicLabel } from 'constants/enums';
import { getTestimonialsByExtIdProxy } from 'src/api/psychicApi';
import type { RightPsychic } from 'types/objectTypes';

export const getPsychicsWithTestimonials = async (
  psychics: Array<RightPsychic> = [],
  custId?: string,
) => {
  const pageSize = 1;
  const psychicPromises = psychics
    ?.map(({ extId }) => getTestimonialsByExtIdProxy({
      extId: extId?.toString(),
      pageSize,
      custId,
    }));
  const psychicsWithTestimonials = await Promise.all([...psychicPromises]);

  return psychicsWithTestimonials;
};

export const getPsychicLabel = (psychic?: RightPsychic) => {
  if (!psychic) {
    return null;
  }

  if (psychic.isRisingStar) {
    return PsychicLabel.RISING_STAR;
  }

  if (psychic.isStaffPick) {
    return PsychicLabel.STAFF_PICK;
  }

  if (psychic.isCustomerPick) {
    return PsychicLabel.CUSTOMER_PICK;
  }

  if (psychic.psychicBadge === 1) {
    return PsychicLabel.SELECT;
  }

  if (psychic.psychicBadge === 2) {
    return PsychicLabel.ELITE;
  }

  if (psychic.psychicBadge === 3) {
    return PsychicLabel.PREMIER;
  }

  if (psychic.isNewPsychic) {
    return PsychicLabel.NEW;
  }

  return null;
};
