/* eslint-disable no-param-reassign */
import cookie from 'js-cookie';

import {
  Asset,
  Block,
  BlockContentTypesItem,
  ImageWrapper,
  Link,
  Psychic,
  Text,
} from 'src/__generated__/graphqlTypes';
import { ItemsLayout, NodeEnv } from 'constants/enums';
import { CURRENT_VIEW_COOKIE, SORT_ID_COOKIE } from 'constants/constants';

import {
  GridViewReducerProps,
  ImageReducerAcc,
  ReducerFilterBlockStore,
  ReducerFilterStore,
  ReducerStore,
  ReducerWrapperFilterStore,
  ListViewReducerType,
  SortImageReducerAcc,
} from '../declarations';

export const reducer = (children: Array<Psychic | Link> | undefined): any => {
  if (!children?.length) {
    return null;
  }

  return children?.reduce((store: ReducerStore, item: Link | Psychic): any => {
    if (item.__typename === 'Psychic') {
      store.psychicFrame = item;
    }

    if (item.__typename === 'Link' && item.slug === 'secondary-link') {
      store.secondaryButton = item;
    }

    if (item.__typename === 'Link' && item.slug === 'pagination-button') {
      store.paginationButton = item;
    }

    if (item.__typename === 'Link' && item.slug === 'pagination-back-button') {
      store.paginationBackButton = item;
    }

    if (item.__typename === 'Link' && item.slug === 'pagination-next-button') {
      store.paginationNextButton = item;
    }

    return store;
  }, {} as ReducerStore) || {};
};

export const setSortIdCookie = (updatedSortId: string) => {
  const currentDate = new Date();
  const expirationDate = new Date(currentDate.getTime() + 365 * 24 * 60 * 60 * 1000);
  const options = {
    secure: process.env.NODE_ENV === NodeEnv.PROD,
    path: '/',
    expires: expirationDate,
  };
  cookie.set(SORT_ID_COOKIE, updatedSortId, options);
};

export const getSortIdCookie = () => cookie.get(SORT_ID_COOKIE);

export const psychicFilterBlockReducer = (children?: Array<Block>): any => {
  if (!children?.length) {
    return null;
  }

  return children.reduce((store: ReducerFilterBlockStore, item: Block): any => {
    if (item.__typename === 'Block' && item.slug === 'filtering-header') {
      store.filteringHeader = item;
    }

    return store;
  }, {} as ReducerFilterBlockStore) || {};
};

export const psychicFilterWrapperReducer = (children?: Array<Block | Link>) => {
  if (!children?.length) {
    return null;
  }

  return children?.reduce((store: ReducerWrapperFilterStore, item: Block | Link) => {
    if (item.__typename === 'Block' && item.slug === 'sort') {
      store.sort = item;
    }

    if (item.__typename === 'Block' && item.slug === 'filters') {
      store.filter = item;
    }

    if (item.__typename === 'Block' && item.slug === 'grid-view') {
      store.gridViewBlock = item;
    }

    if (item.__typename === 'Block' && item.slug === 'list-view') {
      store.listViewBlock = item;
    }

    if (item.__typename === 'Link' && item.slug === 'available') {
      store.availableTitle = item;
    }

    if (item.__typename === 'Link' && item.slug === 'busy') {
      store.busyTitle = item;
    }

    if (item.__typename === 'Link' && item.slug === 'back-filters') {
      store.backToFiltersTitle = item;
    }

    if (item.__typename === 'Link' && item.slug === 'no-match') {
      store.noMatchTitle = item;
    }

    return store;
  }, {} as ReducerWrapperFilterStore) || {};
};

export const psychicFilterReducer = (children?: Array<Link | Block>) => children?.reduce(
  (store: ReducerFilterStore, item: Block | Link | Text) => {
    if (item.__typename === 'Link' && item.slug === 'clear-filter') {
      store.clearFilterButton = item;
    }

    if (item.__typename === 'Link' && item.slug === 'done-filter') {
      store.doneButton = item;
    }

    if (item.__typename === 'Block' && item.slug === 'results') {
      store.results = item;
    }

    if (item.__typename === 'Link' && item.slug === 'price') {
      store.priceTitle = item;
    }

    if (item.__typename === 'Link' && item.slug === 'view') {
      store.viewTitle = item;
    }

    if (item.__typename === 'Link' && item.slug === 'results') {
      store.resultsTitle = item;
    }

    if (item.__typename === 'Link' && item.slug === 'popular') {
      store.popularTitle = item;
    }

    if (item.__typename === 'Text' && item.slug === 'popular-premium') {
      store.popularPremiumFilter = item;
    }

    if (item.__typename === 'Text' && item.slug === 'new-psychics') {
      store.newPsychicsFilter = item;
    }

    if (item.__typename === 'Text' && item.slug === 'customer-favorites') {
      store.customerFavoritesFilter = item;
    }

    if (item.__typename === 'Text' && item.slug === 'staff-picks') {
      store.staffPicksFilter = item;
    }

    if (item.__typename === 'Text' && item.slug === 'rising-stars') {
      store.risingStarsFilter = item;
    }

    if (item.__typename === 'Link' && item.slug === 'availability') {
      store.availabilityTitle = item;
    }

    if (item.__typename === 'Text' && item.slug === 'available-for-appointment') {
      store.availableForAppointment = item;
    }

    return store;
  }, {},
);

export const sortImageReducer = (
  children?: Array<ImageWrapper>,
): SortImageReducerAcc => {
  const store = children?.reduce<{ sortImage?: Asset | null }>(
    (acc, item) => {
      if (item?.__typename === 'ImageWrapper' && item.slug === 'sort') {
        acc.sortImage = item?.image;
      }

      return acc;
    },
    {},
  );

  return store || {};
};

export const filterImageReducer = (children: Array<ImageWrapper>) => {
  const store = children
    .reduce((
      acc: ImageReducerAcc,
      item: ImageWrapper,
    ) => {
      if (item?.__typename === 'ImageWrapper' && item.slug === 'filter') {
        acc.filterImage = item.image;
      }

      return acc;
    }, {});

  return store;
};

export const gridViewReducer = (
  children?: BlockContentTypesItem[],
) => {
  if (!children?.length) {
    return {};
  }

  const store = children.reduce<GridViewReducerProps>((
    store,
    item,
  ) => {
    if (item?.__typename === 'Link' && item.slug === 'grid-view-link') {
      store.gridViewLink = item;
    }

    return store;
  }, {});

  return store || {};
};

export const listViewReducer = (
  children?: BlockContentTypesItem[],
) => {
  if (!children?.length) {
    return {};
  }

  const store = children.reduce<ListViewReducerType>((
    store,
    item,
  ) => {
    if (item?.__typename === 'Link' && item.slug === 'list-view-link') {
      store.listViewLink = item;
    }

    return store;
  }, {});

  return store || {};
};

export const setCurrentViewCookie = (layout: ItemsLayout) => {
  const options = {
    secure: process.env.NODE_ENV === NodeEnv.PROD,
    path: '/',
  };
  const value = layout === ItemsLayout.GRID ? 0 : 1;
  cookie.set(CURRENT_VIEW_COOKIE, value.toString(), options);
};

export const getCurrentViewCookie = () => cookie.get(CURRENT_VIEW_COOKIE);
