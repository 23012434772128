import { FC } from 'react';
import cn from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';
import { useSelector } from 'react-redux';
import cookie from 'js-cookie';

import { useCustomRouter } from 'src/shared/lib/history/hooks';
import type { Store } from 'src/redux/types/storeTypes';
import {
  CommonRichText,
  DataButton,
  Icon,
} from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';
import { RichTextInterface } from 'components/Shared/declarations';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ASK_ACCOUNT_INFO } from 'constants/constants';

import styles from './PriceBasedPackages.module.scss';

import { PriceBasedPackageInterface } from '../config/declarations';
import { getCurrentAccountInfoObject } from '../../../components/Sections/NcOfferPackages/service';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.HEADING_3]: {
    classNames: styles.pricingTitle,
  },
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.paragraph,
  },
};

/* Nested component */
const Content: FC<RichTextInterface> = ({ richText, config }) => {
  if (!richText) {
    return null;
  }

  return (
    <CommonRichText
      content={richText}
      parsersConfig={config}
    />
  );
};

/* Nested component */
const Title = Content;

/* Main component */
const PackagePricing: FC<PriceBasedPackageInterface> = ({
  block,
  tabIndex,
}) => {
  const titleTextList = block.textList;
  const mobileTextList: Array<string> = [];
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const filteredContentList = isMobileViewWidth
    ? block.contentTypesCollection?.items.filter((_, index) => index === tabIndex)
    : block.contentTypesCollection?.items;
  const router = useCustomRouter();

  block.contentTypesCollection?.items.forEach((item, index) => {
    // eslint-disable-next-line no-underscore-dangle
    if (item?.__typename !== 'Block') {
      return null;
    }

    if (index === tabIndex) {
      item?.textList?.forEach((item) => {
        if (titleTextList) {
          mobileTextList.push(item!);
        }
      });
    }
  });

  const handlePackageCookie = (packageName: string, packageType: string) => {
    if (!packageName) {
      return;
    }

    const accountInfo = getCurrentAccountInfoObject(packageName, packageType);
    cookie.set(ASK_ACCOUNT_INFO, JSON.stringify(accountInfo), { secure: true, path: '/' });
  };

  return (
    <div className={styles.packages}>
      {
        !isMobileViewWidth
        && (
          <div
            key="titleBlock"
            className={cn(
              styles.statistics,
              styles.first,
            )}
          >
            <ul className={styles.textList}>
              { block.textList?.map((entry) => (
                <li
                  key={`title${entry}`}
                  className={styles.listItem}
                >
                  <span className={styles.textValue}>
                    {entry}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )
      }
      { filteredContentList?.map((item, index) => {
      // eslint-disable-next-line no-underscore-dangle
        if (item?.__typename !== 'Block') {
          return null;
        }

        const itemsLength = block?.contentTypesCollection?.items?.length ?? 0;
        const activeTextList = isMobileViewWidth ? mobileTextList : item?.textList;
        const linkUrl = item?.link?.src ?? '';

        return (
          <div
            key={item?.sys?.id}
            className={cn(
              styles.statistics,
              index === 0 && styles.firstStatistics,
              index === (itemsLength - 1) && styles.last,
            )}
          >
            { !isMobileViewWidth && <span className={styles.tab}>{item?.title}</span>}
            <Title richText={item?.richTitle} config={parsersConfig} />
            <Content richText={item?.content} config={parsersConfig} />
            <DataButton
              onClick={() => {
                handlePackageCookie(item?.title!, item?.slug!);
                router.push(linkUrl);
              }}
              link={item?.link!}
              className={styles.link}
            >
              {item?.link?.title}
            </DataButton>
            <ul className={styles.textList}>
              {
                activeTextList?.map((text, index) => (
                  <li
                    key={text}
                    className={styles.listItem}
                  >
                    <span className={styles.textValue}>
                      { isMobileViewWidth && (
                        <span className={styles.listTitle}>
                          {titleTextList?.[index]}
                        </span>
                      )}
                      { (index === 2)
                        ? (
                          <span className={styles.listContent}>
                            { text?.split(' ')?.[0]}
                            {(
                              block.image?.url && (
                                <Icon
                                  image={block.image}
                                  className={styles.starIcon}
                                />
                              )
                            )}
                            {`${text?.split(' ')[1]} ${text?.split(' ')[2]}`}
                          </span>
                        )
                        : (text)}
                    </span>
                  </li>
                ))
              }
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default PackagePricing;
