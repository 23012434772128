import { FC } from 'react';
import cn from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import { RichTextParsersConfig } from 'types/objectTypes';
import { CommonRichText } from 'components/Shared/SharedComponents';
import { RichTextInterface } from 'components/Shared/declarations';
import { SectionComponentInterface } from 'types/componentTypes';
import { capitalizeFirstLetter } from 'lib/text.service';

import PriceBasedPackage from './PriceBasedPackage';
import styles from './PriceBasedPackages.module.scss';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.HEADING_2]: {
    classNames: cn(styles.title, styles.titleH2),
  },
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.paragraph,
  },
};

/* Nested component */
const Content: FC<RichTextInterface> = ({ richText, config }) => {
  if (!richText) {
    return null;
  }

  return (
    <CommonRichText
      content={richText}
      parsersConfig={config}
    />
  );
};

/* Nested component */
const Title = Content;

/* Main component */
const PriceBasedPackages: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const { verticalPadding } = extraData || {};
  const [block] = blocks;

  if (!block) {
    return null;
  }

  return (
    <section
      style={{ background: bgColor }}
      className={cn(
        styles.wrapper,
        styles[`sharedWrapperMarginVertical${capitalizeFirstLetter(verticalPadding)}`],
      )}
    >
      <div className={styles.content}>
        <Title richText={block?.richTitle} config={parsersConfig} />
        <Content richText={block?.content} config={parsersConfig} />
        <PriceBasedPackage block={block} />
      </div>
    </section>
  );
};

export default PriceBasedPackages;
