import { forwardRef } from 'react';

import { DataLink } from 'components/Shared/SharedComponents';
import { GAReplacementValue } from 'constants/enums';

import type { NumberInterface } from '../config/declarations';
import { useCtmNumber } from '../lib/hooks';

const Number = forwardRef<HTMLAnchorElement, NumberInterface>(({
  number: defaultNumber,
  ...props
}, ref) => {
  const number = useCtmNumber(defaultNumber);

  return (
    <DataLink
      // @ts-ignore
      ref={ref}
      href={`tel:${number}`}
      gaData={{ [GAReplacementValue.TELEPHONE]: number }}
      {...props}
    >
      {number}
    </DataLink>
  );
});

export default Number;
